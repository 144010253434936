import { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../features/auth/authSlice';

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const { user } = useSelector((state) => state.auth);
  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
    setIsOpen(false);
  };

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  const { firstName, lastName, email, userPicture, username } = user;
  return (
    <div className='flex items-center md:order-2' ref={ref}>
      <button
        type='button'
        className='mr-3 flex rounded-full bg-gray-800 text-sm focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 md:mr-0'
        id='user-menu-button'
        aria-expanded='false'
        data-dropdown-toggle='dropdown'
        onClick={() => setIsOpen((p) => !p)}
      >
        <span className='sr-only'>Open user menu</span>
        <img
          className='h-12 w-12 rounded-full'
          src={
            userPicture
              ? userPicture
              : `https://ui-avatars.com/api/?name=${firstName} ${lastName}`
          }
          alt={username}
        />
      </button>

      <div
        className={`${
          !isOpen ? 'hidden' : ''
        } z-50 my-4 list-none divide-y divide-gray-100 rounded bg-white text-base shadow dark:divide-gray-600 dark:bg-gray-700`}
        id='dropdown'
        style={{
          position: 'absolute',
          right: 10,
          top: 130,
        }}
        data-popper-reference-hidden=''
        data-popper-escaped=''
        data-popper-placement='top'
      >
        <div className='py-3 px-4'>
          <span className='block text-sm text-gray-900 dark:text-white'>
            {`${firstName} ${lastName}`}
          </span>
          <span className='block truncate text-sm font-medium text-gray-500 dark:text-gray-400'>
            {email}
          </span>
        </div>
        <ul className='py-1' aria-labelledby='dropdown'>
          <li>
            <a
              href='/'
              className='block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white'
            >
              Dashboard
            </a>
          </li>
          <li>
            <Link
              to='/create'
              className='block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white'
            >
              Create New Gist
            </Link>
          </li>
          <li>
            <a
              href='/'
              className='block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white'
            >
              Earnings
            </a>
          </li>
          <li>
            <a
              href='/'
              onClick={logoutHandler}
              className='block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white'
            >
              Sign out
            </a>
          </li>
        </ul>
      </div>
      <button
        data-collapse-toggle='mobile-menu-2'
        type='button'
        className='ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden'
        aria-controls='mobile-menu-2'
        aria-expanded='false'
      >
        <span className='sr-only'>Open main menu</span>
        <svg
          className='h-6 w-6'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
            clip-rule='evenodd'
          ></path>
        </svg>
        <svg
          className='hidden h-6 w-6'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
            clip-rule='evenodd'
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default UserProfile;
