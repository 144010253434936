import React, { useEffect, useMemo, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useGetPostFlayerItemsQuery } from "../../features/flayer/flayerApiSlice";
import FlayerDropDown from "./FlayerDropDown";

const FlayerBox = React.forwardRef(({ id }, ref) => {
  const [isShow, setIsShow] = useState(false);
  const outsideRef = useDetectClickOutside({
    onTriggered: () => setIsShow(false),
  });

  const {
    data: allWords,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useGetPostFlayerItemsQuery(id);

  useEffect(() => {
    if (isShow && ref) {
      const contentHeight = ref.current.offsetHeight;
      if (contentHeight < 370) {
        ref.current.style.height = "370px";
      }
    } else {
      ref.current.style.removeProperty("height");
    }
  }, [ref, isShow]);

  const text = useMemo(() => {
    if (!isSuccess) return "";
    const a = allWords.slice().sort((a, b) => {
      const aVotes = a.votes.length;
      const bVotes = b.votes.length;
      if (aVotes > bVotes) return -1;
      if (aVotes < bVotes) return 1;
      return 0;
    });
    return a[0]?.text || "";
  }, [isSuccess, allWords]);

  if (isError || isLoading) {
    console.log(error);
    return null;
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsShow(true)}
      ref={outsideRef}
    >
      {!isShow ? (
        <h2
          className={` ${
            text
              ? "bg-blue-500  text-white"
              : "border border-blue-500 text-black"
          } px-2 py-1`}
        >
          {text ? text : "Add New"}
        </h2>
      ) : (
        <FlayerDropDown
          isShow={isShow}
          allWords={allWords}
          setIsShow={setIsShow}
          id={id}
        />
      )}
    </div>
  );
});
export default FlayerBox;
