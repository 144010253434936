const TOKEN_NAME = 'auth_token';

export const storeToken = (token) => {
  localStorage.setItem(TOKEN_NAME, token);
};
export const getToken = () => {
  return localStorage.getItem(TOKEN_NAME);
};
export const removeToken = () => {
  return localStorage.removeItem(TOKEN_NAME);
};
