const GistDropdown = ({ setShowFormType, showFormType }) => {
  const options = [
    { label: 'Make an Edit', value: `edits` },
    { label: 'Make a Version', value: `versions` },
    { label: 'Make a Reply', value: `replies` },
  ];

  const onChangeHandler = (event) => {
    setShowFormType(event.target.value);
  };

  return (
    <select
      id='countries'
      className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
      onChange={onChangeHandler}
      value={showFormType}
      disabled={showFormType}
    >
      <option value=''> Can you say it better? Tell us!</option>
      {options.map((item) => (
        <option value={item.value}>{item.label}</option>
      ))}
    </select>
  );
};

export default GistDropdown;
