import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../features/auth/authApiSlice';
const SignUp = () => {
  const navigate = useNavigate();
  const [registerUser, { isSuccess, isLoading, isError, error }] =
    useRegisterMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    registerUser(data);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/sign-in');
    }
  }, [isSuccess, navigate]);
  return (
    <div className='md:flex md:justify-center'>
      <div className='my-20 w-full rounded-md border py-5 px-5 md:w-1/3'>
        {isError && <p className='mt-1 text-sm text-red-500'>{error}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <label
              for='first_name'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              First name
            </label>
            <input
              type='text'
              id='first_name'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
              placeholder='John'
              {...register('firstName', { required: 'This field is required' })}
            />
            {errors?.firstName && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.firstName?.message}
              </p>
            )}
          </div>
          <div className='mb-4'>
            <label
              for='last_name'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Last name
            </label>
            <input
              type='text'
              id='last_name'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
              placeholder='Enter last name'
              {...register('lastName', { required: 'This field is required' })}
            />
            {errors?.lastName && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.lastName?.message}
              </p>
            )}
          </div>
          <div className='mb-4'>
            <label
              for='username'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Username
            </label>
            <input
              type='text'
              id='username'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
              placeholder='Enter username'
              {...register('username', { required: 'This field is required' })}
            />
            {errors?.username && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.username?.message}
              </p>
            )}
          </div>

          <div className='mb-4'>
            <label
              for='email'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Email address
            </label>
            <input
              type='email'
              id='email'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
              placeholder='Enter email address'
              {...register('email', { required: 'This field is required' })}
            />
            {errors?.email && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.email?.message}
              </p>
            )}
          </div>
          <div className='mb-4'>
            <label
              for='password'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Password
            </label>
            <input
              type='password'
              id='password'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
              placeholder='•••••••••'
              {...register('password', {
                required: 'This field is required',
                validate: (val) => {
                  if (watch('confirmPassword') !== val) {
                    setError('password', {
                      type: 'error',
                      message: "Password didn't match!",
                    });
                  }
                },
              })}
            />
            {errors?.password && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.password?.message}
              </p>
            )}
          </div>
          <div className='mb-4'>
            <label
              for='password'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Confirm Password
            </label>
            <input
              type='password'
              id='password'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
              placeholder='•••••••••'
              {...register('confirmPassword', {
                required: 'This field is required',
                validate: (val) => {
                  if (watch('confirmPassword') !== val) {
                    setError('password', {
                      type: 'error',
                      message: "Password didn't match!",
                    });
                  }
                },
              })}
            />
            {errors?.confirmPassword && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.confirmPassword?.message}
              </p>
            )}
          </div>

          <div className='mb-4'>
            <label
              class='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300'
              for='file_input'
            >
              Upload file
            </label>
            <input
              class='block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder-gray-400'
              id='file_input'
              type='file'
              {...register('file')}
            />

            {errors?.file && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.file?.message}
              </p>
            )}
          </div>
          <div className='mb-4'>
            <div className=' flex items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='terms'
                  type='checkbox'
                  className='focus:ring-3 h-4 w-4 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600'
                  {...register('terms', {
                    required: 'You must agree to terms and conditions',
                  })}
                />
              </div>
              <label
                for='terms'
                className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-400'
              >
                I agree with the
                <a
                  href='/'
                  className='text-blue-600 hover:underline dark:text-blue-500'
                >
                  terms and conditions
                </a>
                .
              </label>
            </div>
            {errors?.terms && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.terms?.message}
              </p>
            )}
          </div>
          <button
            type='submit'
            className='w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto'
          >
            {isLoading ? <Loading /> : 'Submit'}
          </button>
        </form>

        <div className='mt-4'>
          <ul>
            <li>
              <span>Already have an account? </span>
              <Link to={'/sign-in'} className='text-blue-700'>
                Login Here
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
const Loading = () => (
  <>
    <svg
      role='status'
      className='mr-3 inline h-4 w-4 animate-spin text-white'
      viewBox='0 0 100 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
        fill='#E5E7EB'
      />
      <path
        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
        fill='currentColor'
      />
    </svg>
    Loading...
  </>
);
export default SignUp;
