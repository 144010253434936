import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: {},
  error: {},
};

// create slice
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state, action) => {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

export default filterSlice.reducer;
export const { login, logout } = filterSlice.actions;
