import React from "react";
import HomeContent from "../../components/Home/Home";
import HomeSidebar from "../../components/Sidebar/HomeSidebar";

const Home = () => {
  return (
    <div className="bg-gray-200 pt-5">
      <div className="container">
        <div className="flex  gap-3">
          <div className="w-3/12">
            <HomeSidebar />
          </div>
          <div className="w-9/12">
            <HomeContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
