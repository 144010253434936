import React from 'react';

const Avatar = (props) => {
  const { image, title, subTitle, size = 'small', onlyImage = false } = props;

  return (
    <div className='flex items-center space-x-4'>
      <img
        className='h-10 w-10 rounded-full object-cover'
        src={image}
        alt={title}
      />
      {!onlyImage && (
        <div className='space-y-1 font-medium text-gray-900 dark:text-white'>
          <div>{title}</div>
          <div className='text-sm text-gray-900 dark:text-gray-400'>
            {subTitle}
          </div>
        </div>
      )}
    </div>
  );
};

export default Avatar;
