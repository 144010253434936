import { useMemo, useState } from "react";

const usePagination = ({ data, perPage = 5 }) => {
  const [current, setCurrent] = useState(1);
  const [item, setItem] = useState(perPage);

  const total = useMemo(() => {
    if (data.length === 0) return 1;
    return Math.ceil(data.length / item);
  }, [data, item]);

  const filtered = useMemo(() => {
    if (data.length === 0) return [];
    const start = item * current - item;
    const end = item * current;

    return data.slice(start, end);
  }, [data, item, current]);

  return {
    data: filtered,
    total,
    current,
    setCurrent,
    setPerPage: setItem,
  };
};

export default usePagination;
