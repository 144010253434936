import { useRef, useState } from "react";

import { If, Then } from "react-if";
import { useGetPostQuery } from "../../../features/post/postApiSlice";
import { formateTime, subString } from "../../../utils/string";
import FlayerBox from "../../FlayerBox/FlayerBox";
import Avatar from "../../Shared/Avatar/Avatar";
import SimpleLoader from "../../Shared/Loader/SimpleLoader";
import ReportButton from "../ReportButton/ReportButton";
import VoteCounter from "../Vote/VoteCounter";
const NestedSingleSlider = (props) => {
  const { id, setShowedPostType, showedPostType, isSingle } = props;
  const { data: post, isLoading, isError, isSuccess } = useGetPostQuery(id);

  const contentRef = useRef(null);

  const [isShowAll, setIsShowAll] = useState(false);

  const showMainPost = () => {
    if (showedPostType !== "main" && isSingle) {
      setShowedPostType("main");
    }
  };

  return (
    <>
      <div className="min-h-min overflow-auto">
        <If condition={isLoading}>
          <Then>
            <SimpleLoader />
          </Then>
        </If>
        <If condition={isError}>
          <Then>
            <p className="text-center text-red-500">
              There went something wrong!
            </p>
          </Then>
        </If>
        <If condition={isSuccess && !isError && Object.keys(post).length > 0}>
          <Then>
            <div className="flex justify-between bg-secondary py-2 px-2">
              <p className=" text-base text-white">{post?.title}</p>
              <ReportButton />
            </div>
            <div
              className=" border-b border-l border-r py-4 px-5 text-base transition-all duration-1000"
              ref={contentRef}
            >
              {/* posted user */}
              <div className="my-4 flex items-start justify-between">
                <Avatar
                  image={
                    post?.userId?.userPicture
                      ? post?.userId?.userPicture
                      : `https://ui-avatars.com/api/?name=${post?.userId?.firstName} ${post?.userId?.lastName}`
                  }
                  title={`${post?.userId?.firstName} ${post?.userId?.lastName}`}
                  subTitle={`Posted on: ${formateTime(post?.createdAt)}`}
                />
                <div className="flex items-start gap-2">
                  {/* <p
                className='rounded-md bg-primary px-3 py-1 capitalize text-white hover:cursor-pointer'
                onClick={showMainPost}
              >
                {post?.type}
              </p> */}
                  <FlayerBox id={id} ref={contentRef} />
                  <VoteCounter postId={id} />
                </div>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: isShowAll
                    ? post?.description
                    : subString(post?.description, 1000),
                }}
              />
              {post?.description?.length > 1000 && (
                <button
                  onClick={() => setIsShowAll((p) => !p)}
                  className="text-primary"
                >
                  View {isShowAll ? "Less" : "More"}
                </button>
              )}
            </div>
          </Then>
        </If>
      </div>
    </>
  );
};

export default NestedSingleSlider;
