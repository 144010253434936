import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAddSubjectMutation } from "../../features/subject/subjectApiSlice";
import SimpleLoader from "../Shared/Loader/SimpleLoader";
const NewSubjectForm = ({ id, close }) => {
  const [addSubject, { isSuccess, isLoading }] = useAddSubjectMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmitHandler = (values) => {
    values.parentId = id;
    addSubject(values);
  };

  useEffect(() => {
    if (isSuccess) {
      close();
      reset();
    }
  }, [isSuccess, reset, close]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-8">
        <div>
          <label for="name" className={CLASSES.label}>
            Name
          </label>
          <input
            type="text"
            id="name"
            class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
            placeholder="title"
            {...register("name", { required: "This field is required" })}
          />
          {errors?.name && (
            <p className="mt-1 text-sm text-red-500">{errors?.name?.message}</p>
          )}
        </div>

        <button
          type="submit"
          className="rounded bg-primary px-4 py-2 text-white"
          disabled={isLoading}
        >
          {isLoading ? <SimpleLoader /> : "Create"}
        </button>
      </form>
    </div>
  );
};

// title
// summary
// description

export default NewSubjectForm;
const CLASSES = {
  label: "mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300",
};
