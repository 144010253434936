import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useGetPostCommentsQuery } from '../../features/comment/commentApiSlice';
import AddComment from './AddComment';
import SingleComment from './SingleComment';

const AllComments = ({ postId }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const {
    data: comments,
    isLoading,
    isError,
  } = useGetPostCommentsQuery(postId);

  if (isLoading || isError) return null;

  return (
    <>
      <hr />
      <div className='py-4'>
        {isAuthenticated && <AddComment postId={postId} />}
        <div className='py-3'>
          {comments.length === 0 && <p>No comments found</p>}
          {comments.map((comment) => (
            <SingleComment
              key={Math.random()}
              userPicture={comment?.user?.userPicture}
              text={comment?.text}
              id={comment._id}
              createdAt={comment?.createdAt}
              firstName={comment?.user?.firstName || ''}
              lastName={comment?.user?.lastName}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(AllComments);
