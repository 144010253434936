import React from "react";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetPostVotesQuery,
  useVoteDownMutation,
  useVoteUpMutation,
} from "../../../features/vote/voteApiSlice";
const VoteCounter = ({ postId }) => {
  const { data, isLoading, isError } = useGetPostVotesQuery(postId);
  const [voteUp] = useVoteUpMutation();
  const [voteDown] = useVoteDownMutation();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const up = () => {
    if (isAuthenticated) {
      voteUp(postId);
    } else {
      toast.error("You need to login to perform this action");
    }
  };
  const down = () => {
    if (isAuthenticated) {
      voteDown(postId);
    } else {
      toast.error("You need to login to perform this action");
    }
  };

  if (isLoading || isError) return null;

  return (
    <div className="flex items-center justify-center gap-1 text-2xl">
      <FaArrowAltCircleUp
        className={`${
          data?.isVoted === "like" ? "text-primary" : ""
        } cursor-pointer hover:text-primary`}
        onClick={up}
      />

      <p>{data?.votes}</p>

      <FaArrowAltCircleDown
        className={`${
          data?.isVoted === "dislike" ? "text-primary" : ""
        } cursor-pointer hover:text-primary`}
        onClick={down}
      />
    </div>
  );
};

export default VoteCounter;
