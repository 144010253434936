import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NewGistForm from '../../components/Forms/NewGistForm';
import Content from '../../components/Single/Content/Content';
import PostHeader from '../../components/Single/Gist/PostHeader';
import { useGetPostQuery } from '../../features/post/postApiSlice';
const allTypes = {
  1: 'edit',
  2: 'version',
  3: 'reply',
};
const NewGist = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [sendRequest, setSendRequest] = useState(true);

  const type = searchParams.get('type');
  const id = searchParams.get('id');

  const { data: foundPost } = useGetPostQuery(id, {
    skip: sendRequest,
  });

  useEffect(() => {
    if (id) setSendRequest(false);
  }, [id]);

  return (
    <div className='py-5'>
      <div className='container'>
        <div className='md:flex'>
          <div className='w-full md:w-2/3'>
            <section class='bg-white dark:bg-gray-900'>
              <div class='py-8 px-4 lg:py-16'>
                <h2 class='mb-4 text-center text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white'>
                  Create a new {type ? allTypes[type] : ''}
                </h2>
                <NewGistForm />
              </div>
            </section>
          </div>
          {type && id && (
            <div className='mt-20 w-full overflow-auto md:w-1/3'>
              <div className='rounded-t bg-purple-500 py-2 text-center font-bold text-white'>
                <h2>Selected Post:</h2>
              </div>
              <PostHeader
                image={foundPost?.userId?.userPicture}
                name={`${foundPost?.userId?.firstName} ${foundPost?.userId?.lastName}`}
                date={'foundPost'}
              />
              <Content
                title={foundPost?.title}
                summary={foundPost?.summary}
                description={foundPost?.description}
              />
              <div className='rounded-b bg-teal-500 py-2 text-center font-bold text-white'>
                <button
                  type='button'
                  className='cursor-pointer rounded-md px-2 py-1 text-sm text-white'
                  onClick={() => {
                    navigate(`/gist/${id}`);
                  }}
                >
                  View Post
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewGist;
