import { useEffect, useMemo, useRef, useState } from "react";
import "react-dropdown/style.css";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCommentAlt,
  FaRegCommentAlt,
} from "react-icons/fa";
import { If, Then } from "react-if";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useGetPostCommentsQuery } from "../../../features/comment/commentApiSlice";
import { useGetPostsQuery } from "../../../features/post/postApiSlice";
import AllComments from "../../Comments/AllComments";
import Avatar from "../../Shared/Avatar/Avatar";
import SimpleLoader from "../../Shared/Loader/SimpleLoader";
import GistDropdown from "../../Shared/Menu/GistDropdown";
import NestedSingleSlider from "../NestedSlider/NestedSingleSlider";
import Form from "./Form";
const EditSlider = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  centerMode: false,
  centerPadding: "0",
  arrows: false,
  nextArrow: ".slickNext",
  prevArrow: ".slickPrev",
};

const SingleSlider = ({ id, isSingle }) => {
  const { data, isLoading, isError } = useGetPostsQuery();

  const {
    data: comments,
    isSuccess: commentsIsSuccess,
    isLoading: commentsIsLoading,
  } = useGetPostCommentsQuery(id);

  let slider = useRef(null);

  const [showFormType, setShowFormType] = useState(false);

  const [showedPostType, setShowedPostType] = useState("main");
  const [isShowComments, setIsShowComments] = useState(false);
  const [sliderCountEdit, setSlideCountEdit] = useState(1);
  const [sliderCountVersion, setSlideCountVersion] = useState(1);
  const [currentSliderId, setCurrentSliderId] = useState(id);

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const post = useMemo(() => {
    if (isLoading || isError) return {};
    const post = data.find((p) => p._id === id);
    return post ? post : {};
  }, [data, id, isLoading, isError]);

  useEffect(() => {
    if (!isSingle) {
      setShowedPostType("edits");
    }
  }, [isSingle]);

  const postChildren = useMemo(() => {
    if (isLoading || isError) return [];
    const children = data.reduce((acc, curr) => {
      if (curr.parentId === id && curr.type === showedPostType) {
        acc.push(curr._id);
      }
      return acc;
    }, []);

    if (isSingle && showedPostType === "main") {
      children.unshift(id);
    }

    return children;
  }, [data, id, isLoading, isError, isSingle, showedPostType]);

  const { editsLength, versionsLength, repliesLength } = useMemo(() => {
    return data?.reduce(
      (acc, curr) => {
        if (curr.parentId === id) {
          if (curr.type === "edits") {
            acc.editsLength += 1;
          }
          if (curr.type === "versions") {
            acc.versionsLength += 1;
          }
          if (curr.type === "replies") {
            acc.repliesLength += 1;
          }
        }
        return acc;
      },
      { editsLength: 0, versionsLength: 0, repliesLength: 0 }
    );
  }, [data, id]);

  useEffect(() => {
    setSlideCountEdit(1);
    setSlideCountVersion(1);
    return () => {};
  }, [showedPostType]);

  const next = () => {
    if (showedPostType === "edits") {
      if (sliderCountEdit < postChildren.length) {
        setSlideCountEdit((p) => p + 1);
      }
    } else if (showedPostType === "versions") {
      if (sliderCountVersion < postChildren.length) {
        setSlideCountVersion((p) => p + 1);
      }
    }
    slider.slickNext();
  };

  const previous = () => {
    if (showedPostType === "edits") {
      if (sliderCountEdit > 1) {
        setSlideCountEdit((p) => p - 1);
      }
    } else if (showedPostType === "versions") {
      if (sliderCountVersion > 1) {
        setSlideCountVersion((p) => p - 1);
      }
    }

    slider.slickPrev();
  };

  //   const currentPostComments = useSelector((state) =>
  //     state.comments.all.filter((p) => p.postId === currentSliderId)
  //   );

  const totalComments = useMemo(() => {
    if (!commentsIsSuccess) return 0;
    return comments.length;
  }, [comments, commentsIsSuccess]);

  return (
    <>
      <div className="w-full  overflow-hidden rounded-md bg-white">
        {/* post information */}
        <If condition={isLoading}>
          <Then>
            <SimpleLoader />
          </Then>
        </If>
        <If condition={isError}>
          <Then>
            <p className="text-center text-red-500">
              There went something wrong!
            </p>
          </Then>
        </If>
        <div className="text-gray-900">
          {!showFormType && (
            <h2 className="bg-primary py-3 px-2 text-white">{post?.title}</h2>
          )}
          <div className="">
            {showFormType ? (
              <Form
                setShowFormType={setShowFormType}
                id={currentSliderId}
                showFormType={showFormType}
              />
            ) : (
              <>
                <Slider
                  className="SmallSlider"
                  ref={(c) => (slider = c)}
                  {...EditSlider}
                  beforeChange={(_, next) => {
                    const currentId = slider?.innerSlider?.list
                      .querySelector(`[data-index="${next}"]`)
                      .querySelector(".slider")
                      .getAttribute("data-id");
                    setCurrentSliderId(currentId);
                  }}
                >
                  {postChildren.length > 0 ? (
                    postChildren?.map((childId, i) => (
                      <div
                        className="slider"
                        key={Math.random()}
                        data-id={childId}
                      >
                        <NestedSingleSlider
                          id={childId}
                          showedPostType={showedPostType}
                          setShowedPostType={setShowedPostType}
                          isSingle={isSingle}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="slider" key={Math.random()} data-id={id}>
                      <NestedSingleSlider
                        id={id}
                        showedPostType={showedPostType}
                        setShowedPostType={setShowedPostType}
                        isSingle={isSingle}
                      />
                    </div>
                  )}
                </Slider>
              </>
            )}

            <hr />

            {/* navigation area */}
            <div className="my-4 flex justify-between px-5">
              <div
                className={`flex cursor-pointer items-center gap-2 hover:text-primary ${
                  isShowComments ? "text-primary" : ""
                }`}
                onClick={() => setIsShowComments((p) => !p)}
              >
                {isShowComments ? <FaCommentAlt /> : <FaRegCommentAlt />}(
                {commentsIsLoading ? 0 : totalComments})
              </div>

              <div className="flex gap-2 rounded-md border border-primary p-1">
                <span
                  className="block cursor-pointer rounded-md bg-primary p-1 text-lg text-white"
                  onClick={() => {
                    if (showedPostType === "edits") {
                      previous();
                    }
                  }}
                >
                  <FaAngleLeft />
                </span>
                <span
                  className="cursor-pointer text-primary"
                  onClick={() => setShowedPostType("edits")}
                >
                  {editsLength > 0 ? sliderCountEdit : 0}-{editsLength} Edits
                </span>
                <span
                  className="block cursor-pointer rounded-md bg-primary p-1 text-lg text-white"
                  onClick={() => {
                    if (showedPostType === "edits") {
                      next();
                    }
                  }}
                >
                  <FaAngleRight />
                </span>
              </div>

              <div className="flex gap-2 rounded-md border border-primary p-1">
                <span
                  className="block cursor-pointer rounded-md bg-green-600 p-1 text-lg text-white"
                  onClick={() => {
                    if (showedPostType === "versions") {
                      previous();
                    }
                  }}
                >
                  <FaAngleLeft />
                </span>
                <span
                  className="cursor-pointer text-primary"
                  onClick={() => setShowedPostType("versions")}
                >
                  {versionsLength > 0 ? sliderCountVersion : 0}-{versionsLength}{" "}
                  Version
                </span>
                <span
                  className="block cursor-pointer rounded-md bg-green-600 p-1 text-lg text-white"
                  onClick={() => {
                    if (showedPostType === "versions") {
                      next();
                    }
                  }}
                >
                  <FaAngleRight />
                </span>
              </div>

              <div className="flex gap-2 rounded-md border border-primary p-1">
                <p>
                  Replies{" "}
                  <span className="text-primary">({repliesLength})</span>
                </p>
              </div>
              {isAuthenticated ? (
                <div className="flex gap-2">
                  <Avatar
                    image={
                      user.userPicture
                        ? user.userPicture
                        : `https://ui-avatars.com/api/?name=${user?.firstName} ${user?.lastName}`
                    }
                    onlyImage={true}
                  />

                  <div className="flex cursor-pointer items-center gap-2 rounded-xl ">
                    <GistDropdown
                      setShowFormType={setShowFormType}
                      showFormType={showFormType}
                    />
                  </div>
                </div>
              ) : (
                <p>
                  Please{" "}
                  <Link className="text-primary" to="/sign-in">
                    login
                  </Link>{" "}
                  to reply!
                </p>
              )}
            </div>

            {/* comments area */}
            {isShowComments && (
              <div className="px-4 py-3">
                <AllComments postId={currentSliderId} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSlider;
