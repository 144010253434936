import React, { useCallback, useEffect, useMemo } from "react";
import { EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetPostsQuery } from "../../../features/post/postApiSlice";
import SingleSlider from "./SingleSlider";

import { If, Then, When } from "react-if";
import usePagination from "../../../hook/usePagination";
import SimpleLoader from "../../Shared/Loader/SimpleLoader";
import Pagination from "../../Shared/Pagination";

const AllSlider = () => {
  const { data, isLoading, isError, isSuccess } = useGetPostsQuery();
  const mainPostsIds = useMemo(() => {
    if (isLoading || isError) return [];
    return data.reduce((acc, cur) => {
      if (cur.type === "main") {
        acc.push(cur._id);
      }
      return acc;
    }, []);
  }, [data, isLoading, isError]);

  const {
    data: filteredPostIds,
    current: currentPage,
    setCurrent: setCurrentPage,
    total: totalPage,
  } = usePagination({ data: mainPostsIds, perPage: 5 });

  const getPostChildren = useCallback(
    (parentId) => {
      if (isLoading || isError) return [];
      return data.reduce((acc, cur) => {
        if (cur.type !== "main" && cur.parentId === parentId) {
          acc.push(cur._id);
        }
        return acc;
      }, []);
    },
    [data, isLoading, isError]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="min-h-screen pb-10">
      <If condition={isLoading}>
        <Then>
          <SimpleLoader />
        </Then>
      </If>
      <If condition={isError}>
        <Then>
          <p className="text-center text-red-500">
            There went something wrong!
          </p>
        </Then>
      </If>
      <If condition={isSuccess && !isError}>
        <Then>
          <div>
            <div>
              {filteredPostIds.map((postId) => (
                <div className="mb-32">
                  <>
                    <Swiper
                      effect={"cards"}
                      grabCursor={true}
                      modules={[EffectCards]}
                    >
                      <SwiperSlide key={Math.random()}>
                        <SingleSlider id={postId} isSingle={true} />
                      </SwiperSlide>
                      {getPostChildren(postId).map((id) => (
                        <SwiperSlide key={Math.random()}>
                          <SingleSlider id={id} isSingle={false} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                </div>
              ))}
            </div>
            <When condition={mainPostsIds.length > 5}>
              <div className="flex justify-center">
                <Pagination
                  total={totalPage}
                  onChange={(page) => {
                    setCurrentPage(page);
                  }}
                  onNext={() => setCurrentPage((p) => p + 1)}
                  onPrevious={() => setCurrentPage((p) => p - 1)}
                  current={currentPage}
                />
              </div>
            </When>
          </div>
        </Then>
      </If>
    </div>
  );
};

export default AllSlider;
