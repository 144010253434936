import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAddNewMutation } from "../../features/flayer/flayerApiSlice";

const AddFlayer = ({ id: postId }) => {
  const [addNew] = useAddNewMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const onSubmit = (data) => {
    if (isAuthenticated) {
      reset();
      addNew({ ...data, post: postId });
    } else {
      toast.error("You need to login to perform this action!");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-48 ">
      <input
        type="text"
        className={`block w-full rounded-md border bg-white px-2 py-1 outline-none ${
          errors?.text ? "border-red-500" : ""
        }`}
        placeholder="add new "
        {...register("text", {
          required: "This field is required!",
          minLength: {
            value: 3,
            message: "Text must be greater than 3",
          },
          maxLength: {
            value: 20,
            message: "Text must not be greater than 20",
          },
        })}
      />
      {errors?.text && (
        <p className="mt-1 text-xs text-red-500">{errors?.text?.message}</p>
      )}
    </form>
  );
};

export default AddFlayer;
