import React from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../../features/auth/authSelector";
import DropDownItem from "./DropDownItem";

const DropDownList = ({ items = [] }) => {
  const user = useSelector(userSelector);
  return (
    <>
      <div className="py-1 text-sm text-gray-700 dark:text-gray-200">
        {items
          .slice()
          .sort((a, b) => {
            const aVotes = a.votes.length;
            const bVotes = b.votes.length;
            if (aVotes > bVotes) return -1;
            if (aVotes < bVotes) return 1;
            return 0;
          })
          .map((item) => (
            <DropDownItem
              key={item._id}
              id={item._id}
              postId={item.post}
              text={item?.text || ""}
              count={item?.votes?.length}
              isSelected={item.votes.includes(user._id)}
            />
          ))}
      </div>
    </>
  );
};

export default DropDownList;
