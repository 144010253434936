import { subString } from '../../../utils/string';

const Content = ({ description, summary, title }) => {
  return (
    <div className='border-r border-l border-b p-4'>
      <h2 className='mb-3 text-xl font-bold'>{title}</h2>
      <div className='mb-2'>{summary}</div>
      <div
        dangerouslySetInnerHTML={{
          __html: subString(description, 1000),
        }}
      />
    </div>
  );
};

export default Content;
