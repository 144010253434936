import React, { useMemo } from "react";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import { useGetSubjectsQuery } from "../../features/subject/subjectApiSlice";
import SimpleLoader from "../Shared/Loader/SimpleLoader";

const HomeSidebar = () => {
  return (
    <div className="w-full ">
      <div className="w-full rounded-lg border border-gray-200 bg-white font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white">
        <div
          aria-current="true"
          className="block w-full cursor-pointer rounded-t-lg border-b border-gray-200 bg-blue-700 py-5 px-4 text-center text-white dark:border-gray-600 dark:bg-gray-800"
        >
          Subjects
        </div>
        <Subject />
      </div>
    </div>
  );
};

const Subject = ({ id, name }) => {
  const { data, isError, isLoading, isSuccess } = useGetSubjectsQuery();
  const subjects = useMemo(() => {
    if (isLoading || isError) return [];

    return data.reduce((acc, cur) => {
      if (id) {
        if (cur.parentId === id) {
          acc.push(cur);
        }
      } else {
        if (!("parentId" in cur)) {
          acc.push(cur);
        }
      }
      return acc;
    }, []);
  }, [data, isLoading, isError, id]);

  return (
    <div className="w-full overflow-x-auto px-4">
      <If condition={isError}>
        <Then>
          <p className="text-red-500">There went something wrong!</p>
        </Then>
      </If>
      <If condition={!isError && isLoading}>
        <Then>
          <SimpleLoader />
        </Then>
      </If>

      <If condition={isSuccess && !isLoading}>
        <Then>
          {subjects?.map((sub) => (
            <>
              <Link to={`/subject/${sub._id}`} className={`subject-link`}>
                {sub.name}
              </Link>
              <ChildSubject id={sub?._id} />
            </>
          ))}
        </Then>
      </If>
    </div>
  );
};

const ChildSubject = ({ id }) => {
  const { data, isError, isLoading } = useGetSubjectsQuery();
  const subjects = useMemo(() => {
    if (isLoading || isError) return [];

    return data.reduce((acc, cur) => {
      if (cur.parentId === id) {
        acc.push(cur);
      }

      return acc;
    }, []);
  }, [data, isLoading, isError, id]);
  if (subjects.length <= 0) return null;
  return (
    <div className="ml-3 w-fit">
      <If condition={subjects.length > 0}>
        <Then>
          {subjects?.map((sub) => (
            <>
              <Link to={`/subject/${sub._id}`} className={`subject-link`}>
                {sub.name}
              </Link>
              <ChildSubject id={sub?._id} />
            </>
          ))}
        </Then>
        <Else>
          <h2>No found!</h2>
        </Else>
      </If>
    </div>
  );
};

export default HomeSidebar;
