import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAddPostMutation } from '../../features/post/postApiSlice';
import { useGetSubjectsQuery } from '../../features/subject/subjectApiSlice';
import SimpleLoader from '../Shared/Loader/SimpleLoader';
const NewGistForm = () => {
  const { data: subjects, isLoading, isError } = useGetSubjectsQuery();
  const [addPost, { isSuccess, isLoading: addLoading }] = useAddPostMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const onSubmitHandler = (values) => {
    addPost(values);
  };

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);

  if (isLoading || isError) return <SimpleLoader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitHandler)} className='space-y-8'>
        {/* post title */}
        <div>
          <label for='title' className={CLASSES.label}>
            Title
          </label>
          <input
            type='text'
            id='title'
            class='focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400'
            placeholder='title'
            {...register('title', { required: 'This field is required' })}
          />
          {errors?.title && (
            <p className='mt-1 text-sm text-red-500'>
              {errors?.title?.message}
            </p>
          )}
        </div>
        {/* post subject */}
        <div>
          <label
            for='subject'
            class='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400'
          >
            Select Subject
          </label>

          <input
            type='hidden'
            className='hidden'
            value={watch('postSubject')}
            {...register('postSubject', { required: 'This field is required' })}
          />
          <select
            id='subject'
            class='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
            onChange={(e) => setValue('postSubject', e.target.value)}
            value={watch('postSubject')}
          >
            <option value=''>Select Subject</option>
            {subjects.map((subject) => (
              <option key={subject._id} value={subject._id}>
                {subject.name}
              </option>
            ))}
          </select>
          {errors?.postSubject && (
            <p className='mt-1 text-sm text-red-500'>
              {errors?.postSubject?.message}
            </p>
          )}
        </div>
        {/* post summary */}
        <div>
          <label for='subject' className={CLASSES.label}>
            Summary
          </label>
          <input
            type='text'
            id='subject'
            class='focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900 shadow-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400'
            placeholder='summary'
            {...register('summary', { required: 'This field is required' })}
          />
          {errors?.summary && (
            <p className='mt-1 text-sm text-red-500'>
              {errors?.summary?.message}
            </p>
          )}
        </div>
        {/* post description */}
        <div class='sm:col-span-2'>
          <label for='message' className={CLASSES.label}>
            Description
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={watch('description') || ''}
            onChange={(event, editor) => {
              setValue('description', editor.getData());
            }}
            onReady={() => {
              console.log('editor is ready!');
            }}
          />

          <textarea
            value={watch('description')}
            {...register('description', { required: 'This field is required' })}
            className='hidden'
          />

          {errors?.description && (
            <p className='mt-1 text-sm text-red-500'>
              {errors?.description?.message}
            </p>
          )}
        </div>
        <button
          type='submit'
          className='rounded bg-primary px-4 py-2 text-white'
          disabled={addLoading}
        >
          {addLoading ? <SimpleLoader /> : 'Create'}
        </button>
      </form>
    </div>
  );
};

// title
// summary
// description

export default NewGistForm;
const CLASSES = {
  label: 'mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300',
};
