import React from "react";
import { Link } from "react-router-dom";
import logoImage from "./web-logo.png";
const Logo = () => {
  return (
    <Link to="/" className="flex items-center">
      <img src={logoImage} className="mr-3 h-24 w-44" alt="Flowbite Logo" />
    </Link>
  );
};

export default Logo;
