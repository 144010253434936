import React, { useMemo } from "react";
import { Tree } from "react-organizational-chart";
import ItemContent from "../../components/Tree/ItemContent";
import Item from "../../components/Tree/TreeItem";
import { useGetSubjectsQuery } from "../../features/subject/subjectApiSlice";

const TreePage = () => {
  const { data, isLoading, isError } = useGetSubjectsQuery();

  const subjects = useMemo(() => {
    if (isLoading || isError) return [];

    return data.reduce((acc, cur) => {
      console.log(acc.length);
      if (!("parentId" in cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  }, [data, isLoading, isError]);

  return (
    <div className="mt-20">
      <Tree
        lineBorderRadius="10px"
        lineColor="blue"
        lineHeight="30px"
        lineWidth="4px"
        label={<ItemContent title={"Main"} />}
      >
        {subjects.map((subject) => (
          <Item key={subject._id} id={subject._id} title={subject.name} />
        ))}
      </Tree>
    </div>
  );
};

export default TreePage;
