import { Link } from 'react-router-dom';

const AuthMenu = () => {
  return (
    <div className='flex items-center md:order-2'>
      <ul className='mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium'>
        <li>
          <Link
            to='/sign-in'
            className='block rounded  py-2 pr-4 pl-3 text-gray-700 dark:text-white md:bg-transparent md:p-0'
            aria-current='page'
          >
            Login
          </Link>
        </li>
        <li>
          <Link
            to='/sign-up'
            className='block border-b border-gray-100 py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:p-0 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:bg-transparent md:dark:hover:text-white'
          >
            Sign Up
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AuthMenu;
