import * as React from "react";
import NewSubjectForm from "../../Forms/NewSubjectForm";
import Modal from "../../Shared/Modal";

function ItemModal({ isOpen, setIsOpen, id }) {
  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div>
          <NewSubjectForm id={id} close={() => setIsOpen(false)} />
        </div>
      </Modal>
    </>
  );
}

export default ItemModal;
