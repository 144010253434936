import * as React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    overflowY: "auto",
    maxHeight: "100vh",
  },
};
Modal.setAppElement("body");

function ModalWrapper({ isOpen, setIsOpen, children }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Tree Item modal"
      >
        {children}
      </Modal>
    </>
  );
}

export default ModalWrapper;
