import apiSlice from '../api/apiSlice';

const voteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVotes: builder.query({
      query: () => ({
        url: '/vote',
        method: 'get',
      }),
      providesTags: ['Votes'],
    }),
    getPostVotes: builder.query({
      query: (postId) => ({
        url: `/vote/${postId}`,
        method: 'get',
      }),
      providesTags: (result, error, arg) => [{ type: 'PostVotes', id: arg }],
    }),
    voteUp: builder.mutation({
      query: (postId) => ({
        url: `/vote/up/${postId}`,
        method: 'post',
      }),
      invalidatesTags: (result, error, arg) => [
        'Votes',
        { type: 'PostVotes', id: arg },
      ],
    }),
    voteDown: builder.mutation({
      query: (postId) => ({
        url: `/vote/down/${postId}`,
        method: 'post',
      }),
      invalidatesTags: (result, error, arg) => [
        'Votes',
        { type: 'PostVotes', id: arg },
      ],
    }),
  }),
});

export const {
  useGetVotesQuery,
  useVoteDownMutation,
  useVoteUpMutation,
  useGetPostVotesQuery,
} = voteApiSlice;
