import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useGetSubjectsQuery } from "../../features/subject/subjectApiSlice";
import ItemModal from "./Modal/ItemModal";
const MAX_CHILD = 8;

function ItemContent({ title, id }) {
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showItem = () => {
    handleClose();
    setShow(true);
  };

  const { data, isLoading, isError } = useGetSubjectsQuery();
  const children = React.useMemo(() => {
    if (isLoading || isError) return [];
    return data.reduce((acc, cur) => {
      if (cur?.parentId === id) {
        acc.push(cur);
      }
      return acc;
    }, []);
  }, [data, isLoading, isError, id]);

  return (
    <div>
      <p
        className="inline-block cursor-pointer bg-blue-500 px-3 py-1 text-white "
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {title}
      </p>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => alert("View")}>View</MenuItem>
        {children.length < MAX_CHILD && (
          <MenuItem onClick={showItem}>New Subject</MenuItem>
        )}
      </Menu>
      <ItemModal isOpen={show} setIsOpen={setShow} id={id} />
    </div>
  );
}

export default ItemContent;
