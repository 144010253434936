import React, { useMemo } from "react";
import { TreeNode } from "react-organizational-chart";
import { useGetSubjectsQuery } from "../../features/subject/subjectApiSlice";
import ItemContent from "./ItemContent";

const Item = ({ id, title = "value" }) => {
  const { data, isLoading, isError } = useGetSubjectsQuery();
  const subjects = useMemo(() => {
    if (isLoading || isError) return [];
    return data.reduce((acc, cur) => {
      if (cur.parentId === id) {
        acc.push(cur);
      }
      return acc;
    }, []);
  }, [data, isLoading, isError, id]);

  return (
    <>
      <TreeNode label={<ItemContent title={title} id={id} />} className="">
        {subjects.map((subject) => (
          <Item key={Math.random()} id={subject._id} title={subject.name} />
        ))}
      </TreeNode>
    </>
  );
};

export default Item;
