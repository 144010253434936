import { useSelector } from 'react-redux';
import AuthMenu from './AuthMenu';
import Logo from './Logo';
import Menu from './Menu';
import UserProfile from './UserProfile';

const Header = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <div>
      <nav className='relative rounded border-b border-gray-200 bg-white px-2 py-5 shadow-lg dark:bg-gray-800 sm:px-4'>
        <div className='mx-auto flex flex-wrap items-center justify-between '>
          <Logo />
          {isAuthenticated ? <UserProfile /> : <AuthMenu />}
          <Menu />
        </div>
      </nav>
    </div>
  );
};

export default Header;
