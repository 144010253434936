import jwtDecode from 'jwt-decode';
import { storeToken } from '../../utils/token';
import apiSlice from '../api/apiSlice';
import { login } from './authSlice';

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/auth/sign-in',
        method: 'post',
        body: data,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          storeToken(data.token);
          const user = jwtDecode(data.token);
          dispatch(login(user));
        } catch (error) {
          console.log('Login failed!');
        }
      },
    }),
    register: builder.mutation({
      query: (data) => {
        const formData = new FormData();

        Object.entries(data).forEach(([name, value]) => {
          formData.append(name, name === 'file' ? value[0] : value);
        });

        return {
          url: '/auth/sign-up',
          method: 'post',
          body: formData,
        };
      },
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authApiSlice;
