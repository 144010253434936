import apiSlice from "../api/apiSlice";

const flayerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlayerItems: builder.query({
      query: () => ({
        url: "/flayer",
        method: "get",
      }),
    }),
    getPostFlayerItems: builder.query({
      query: (postId) => ({
        url: `/flayer/by-post/${postId}`,
        method: "get",
      }),
    }),
    vote: builder.mutation({
      query: ({ id }) => ({
        url: `/flayer/vote/${id}`,
        method: "put",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch, getState }) {
        const state = getState();
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getPostFlayerItems",
            arg.postId,
            (draft) => {
              const userId = state.auth.user._id;

              const find = draft.find((i) => i._id === arg.id);
              if (find?.votes.includes(userId)) {
                find.votes = find.votes.filter((i) => i !== userId);
              } else {
                find.votes.push(userId);
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    addNew: builder.mutation({
      query: (data) => ({
        url: `/flayer`,
        method: "post",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              "getPostFlayerItems",
              arg.post,
              (draft) => {
                draft.push(res.data);
              }
            )
          );
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetFlayerItemsQuery,
  useGetPostFlayerItemsQuery,
  useVoteMutation,
  useAddNewMutation,
} = flayerApi;
