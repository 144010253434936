import Avatar from '../../Shared/Avatar/Avatar';

const PostHeader = ({ image, name, date }) => {
  return (
    <div>
      <div className=''>
        <div className='border py-4 px-5 text-base'>
          {/* posted user */}
          <div className='my-4'>
            <Avatar
              image={image ? image : `https://ui-avatars.com/api/?name=${name}`}
              title={`${name}`}
              subTitle={`Posted on: ${date}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostHeader;
