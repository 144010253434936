import AllSlider from "./Sliders/AllSlider";

const HomeContent = () => {
  return (
    <div className="">
      <AllSlider />
    </div>
  );
};

export default HomeContent;
