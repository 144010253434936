import React, { useState } from "react";
import {
  FaCheckCircle,
  FaFlag,
  FaHeart,
  FaRegCircle,
  FaRegFlag,
  FaRegHeart,
} from "react-icons/fa";

const ReportButton = () => {
  const [isReported, setIsReported] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  return (
    <div>
      <div className="flex gap-3 text-xl">
        <div
          onClick={() => setIsReported((p) => !p)}
          className="cursor-pointer"
        >
          {isReported ? <FaFlag className="text-red-500" /> : <FaRegFlag />}
        </div>
        <div onClick={() => setIsCheck((p) => !p)} className="cursor-pointer">
          {isCheck ? (
            <FaCheckCircle className="text-blue-500" />
          ) : (
            <FaRegCircle />
          )}
        </div>
        <div
          onClick={() => setIsBookmarked((p) => !p)}
          className="cursor-pointer"
        >
          {isBookmarked ? (
            <FaHeart className="text-pink-600" />
          ) : (
            <FaRegHeart />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportButton;
