import apiSlice from "../api/apiSlice";

const postApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: () => ({
        url: "/post/get-all",
        method: "get",
      }),
    }),
    getPost: builder.query({
      query: (id) => ({
        url: `/post/get/post/${id}`,
        method: "get",
      }),
      providesTags: (result, error, arg) => [{ type: "Post", id: arg }],
    }),
    addPost: builder.mutation({
      query: (data) => ({
        url: "/post/create",
        method: "post",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getPosts", undefined, (draft) => {
              draft.push(data);
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useGetPostsQuery, useAddPostMutation, useGetPostQuery } =
  postApiSlice;
