import React, { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  useAddPostMutation,
  useGetPostQuery,
} from '../../../features/post/postApiSlice';
import { useGetSubjectsQuery } from '../../../features/subject/subjectApiSlice';
import SimpleLoader from '../../Shared/Loader/SimpleLoader';
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

const allTypes = {
  edits: 1,
  versions: 2,
  replies: 3,
};
const Form = ({ setShowFormType, id, showFormType }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const { data: post, isLoading: fetchLoading } = useGetPostQuery(id);

  const { data: subjects, isLoading: subjectLoading } = useGetSubjectsQuery();

  const [
    addPost,
    { isSuccess: addPostIsSuccess, isLoading: addPostIsLoading },
  ] = useAddPostMutation();

  useEffect(() => {
    if (addPostIsSuccess) setShowFormType('');
  }, [addPostIsSuccess, setShowFormType]);

  const firstSubject = useMemo(() => {
    if (subjectLoading) return {};
    return subjects?.[0] || {};
  }, [subjects, subjectLoading]);

  const formHandler = (values) => {
    const data = {
      ...values,
      parentId: id,
      type: allTypes[showFormType],
      postSubject: post?.postSubject || firstSubject?._id,
    };

    addPost(data);
  };

  useEffect(() => {
    if (!fetchLoading && Object.keys(post).length > 0) {
      setValue('title', post?.title);
      setValue('description', post?.description);
      setValue('summary', post?.summary);
    }
  }, [post, setValue, fetchLoading]);

  return (
    <div className=''>
      {(subjectLoading || addPostIsLoading || fetchLoading) && (
        <div className='absolute z-[999999] flex h-full w-full items-center justify-center bg-[#0000003d]'>
          <div className=''>
            <SimpleLoader />
          </div>
        </div>
      )}
      <form className='' onSubmit={handleSubmit(formHandler)}>
        <div className='bg-secondary py-2 px-2 text-base text-white'>
          <input
            type='text'
            placeholder='your title'
            className='w-full border-b bg-transparent pl-3 text-white outline-none placeholder:text-white'
            {...register('title', { required: 'This field is required' })}
          />

          {errors?.title && (
            <p className='mt-1 text-sm text-red-500'>
              {errors?.title?.message}
            </p>
          )}
        </div>
        <div className='border-b border-l border-r py-4 px-5 text-base'>
          {/* posted user */}

          <div className='mb-2 py-2 text-base'>
            <label>Summary</label>
            <input
              type='text'
              placeholder='your summary'
              className='w-full border-b bg-transparent py-1 outline-none '
              {...register('summary', { required: 'This field is required' })}
            />
            {errors?.summary && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.summary?.message}
              </p>
            )}
          </div>

          <div>
            <label>Description</label>
            <ReactQuill
              theme='snow'
              {...register('description', {
                required: 'This field is required',
              })}
              onChange={(data) => setValue('description', data)}
              value={watch('description')}
              modules={modules}
              formats={formats}
            />
            {errors?.description && (
              <p className='mt-1 text-sm text-red-500'>
                {errors?.description?.message}
              </p>
            )}
          </div>
        </div>
        <div className='my-4 flex items-center justify-end gap-1 pr-4'>
          <button
            type='button'
            className='rounded-md bg-red-400 px-3 py-1 capitalize text-white hover:cursor-pointer'
            onClick={() => setShowFormType('')}
          >
            Discard
          </button>
          <button
            type='submit'
            className='rounded-md bg-primary px-3 py-1 capitalize text-white hover:cursor-pointer'
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default memo(Form);
