import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';
import { getToken, removeToken } from '../../utils/token';
import { login, logout } from '../auth/authSlice';
const BASE_URL = '/api';
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = getToken();
    headers.set('Authorization', token ? token : '');
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    const token = getToken();

    if (token) {
      const user = jwtDecode(token);
      if (user.exp > new Date().getTime()) {
        api.dispatch(login(user));
      } else {
        removeToken();
      }
    }

    if (result?.error?.status === 401) {
      api.dispatch(logout());
      removeToken();
    }
    return result;
  },
  tagTypes: ['Votes', 'PostVotes'],
  endpoints: (builder) => ({}),
});

export default apiSlice;
