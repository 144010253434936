import apiSlice from '../api/apiSlice';

const commentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query({
      query: () => ({
        url: '/comments/all',
        method: 'get',
      }),
    }),
    getPostComments: builder.query({
      query: (postId) => ({
        url: `/comments/comments-by-postId/${postId}`,
        method: 'get',
      }),
    }),
    addComment: builder.mutation({
      query: (data) => ({
        url: '/comments/create',
        method: 'post',
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              'getPostComments',
              arg.postId,
              (draft) => {
                draft.push(data);
              }
            )
          );
        } catch (error) {}
      },
    }),
    getReplies: builder.query({
      query: () => ({
        url: '/comments/reply/all',
        method: 'get',
      }),
    }),
    addReply: builder.mutation({
      query: (data) => {
        const { text, commentId } = data;
        return {
          url: `/comments/reply/create/${commentId}`,
          method: 'post',
          body: { text },
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              'addReply',
              arg.commentId,
              (draft) => {
                draft.push(data);
              }
            )
          );
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useAddCommentMutation,
  useGetRepliesQuery,
  useAddReplyMutation,
  useGetPostCommentsQuery,
} = commentApiSlice;
