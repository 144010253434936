import moment from 'moment';

export const subString = (string, length = 300) => {
  if (!string) return '';
  if (string.length < length) return string;
  return string.substring(0, length) + '...';
};
export const formateTime = (dateTime) => {
  if (!dateTime) return '';
  return moment(dateTime).format('DD MMMM YYYY, h:mm:ss a');
};
