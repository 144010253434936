import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useVoteMutation } from "../../features/flayer/flayerApiSlice";

const DropDownItem = ({ text, count = 0, isSelected, id, postId }) => {
  const [vote] = useVoteMutation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const handler = () => {
    if (isAuthenticated) {
      vote({ id, postId });
    } else {
      toast.error("You need to login to perform this action");
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between py-2 px-4 text-base hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
        <p className="">{text}</p>
        <div className="flex items-center gap-1">
          <p>{count}</p>
          <input
            type="checkbox"
            name=""
            id=""
            checked={isSelected}
            onChange={handler}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default DropDownItem;
