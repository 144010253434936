import { formateTime } from '../../utils/string';

const Reply = ({ text, userName, image, createdAt }) => {
  return (
    <div className='mt-3 flex w-1/2 items-start space-x-4 rounded-md bg-gray-200 py-4 pr-5 pl-3'>
      <img
        className='h-10 w-10 rounded-full object-cover'
        src={image ? image : `https://ui-avatars.com/api/?name=${userName}`}
        alt={'title'}
      />

      <div className='space-y-1 font-medium  dark:text-white'>
        <div className='flex items-end gap-x-3'>
          <h2 className='text-base font-bold text-primary'>{userName}</h2>
          <p className='text-sm text-gray-600'>{formateTime(createdAt)} </p>
        </div>
        <p className='text-base  text-gray-800'>{text}</p>
        <div className='mt-1 flex gap-x-3 text-sm'>
          <p className='cursor-pointer transition-colors hover:text-primary'>
            Like
          </p>
        </div>
      </div>
    </div>
  );
};

export default Reply;
