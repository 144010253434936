import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./Pagination.css";
const Pagination = (props) => {
  const { total, onChange, onNext, onPrevious, current } = props;
  return (
    <div>
      <nav aria-label="Page navigation example">
        <ul class="inline-flex items-center -space-x-px">
          <li>
            <Previous {...props} />
          </li>

          {Array.from(Array(total)).map((_, i) => (
            <li
              className={`pagination-item ${current === i + 1 ? "active" : ""}`}
              onClick={(e) => {
                onChange(i + 1, e);
              }}
            >
              {i + 1}
            </li>
          ))}

          <li>
            <Next {...props} />
          </li>
        </ul>
      </nav>
    </div>
  );
};
const Previous = ({ onPrevious, current }) => {
  return (
    <>
      <button
        onClick={onPrevious}
        class="pagination-prev-btn"
        disabled={current <= 1}
      >
        <span class="sr-only">Next</span>
        <FaAngleLeft />
      </button>
    </>
  );
};
const Next = ({ onNext, current, total }) => {
  return (
    <>
      <button
        onClick={onNext}
        disabled={current >= total}
        className="pagination-next-btn"
      >
        <span class="sr-only">Next</span>
        <FaAngleRight />
      </button>
    </>
  );
};

export default Pagination;
