import { memo, useEffect, useMemo, useState } from 'react';
import { useGetRepliesQuery } from '../../features/comment/commentApiSlice';
import { formateTime } from '../../utils/string';
import AddReply from '../Reply/AddReply';
import Replies from '../Reply/Replies';

const SingleComment = (props) => {
  const { userPicture, text, createdAt, firstName, lastName, id } = props;
  const [isShowReplies, setIsShowReplies] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const { data, isLoading, isError } = useGetRepliesQuery();

  const totalRepliesLength = useMemo(() => {
    if (isLoading || isError) return 0;
    return data.filter((reply) => reply.commentId === id).length;
  }, [isLoading, isError, id, data]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div
        className='mt-3 flex w-1/2 items-start space-x-4 rounded-md bg-gray-200 py-4 pr-5 pl-3'
        onSubmit={(e) => e.preventDefault()}
      >
        <img
          className='h-10 w-10 rounded-full object-cover'
          src={
            userPicture
              ? userPicture
              : `https://ui-avatars.com/api/?name=${firstName} ${lastName}`
          }
          alt={'title'}
        />

        <div className='space-y-1 font-medium  dark:text-white'>
          <div className='flex items-end gap-x-3'>
            <h2 className='text-base font-bold text-primary'>
              {firstName} {lastName}
            </h2>
            <p className='text-sm text-gray-600'>{formateTime(createdAt)} </p>
          </div>
          <p className='text-base  text-gray-800'> {text} </p>
          <div className='mt-1 flex gap-x-3 text-sm'>
            <p
              className={`cursor-pointer transition-colors hover:text-primary ${
                isLiked ? 'text-primary' : ''
              }`}
              onClick={() => setIsLiked((p) => !p)}
            >
              {isLiked ? 'Liked' : 'Like'}
            </p>
            <p
              className={`cursor-pointer transition-colors hover:text-primary ${
                isShowReplies ? 'text-primary' : ''
              }`}
              onClick={() => setIsShowReplies((p) => !p)}
            >
              Reply ({totalRepliesLength})
            </p>
          </div>
        </div>
      </div>

      {/* replies */}
      {isShowReplies && (
        <div className='ml-16 py-3'>
          <Replies commentId={id} />
          <AddReply commentId={id} setIsShowReplies={setIsShowReplies} />
        </div>
      )}
    </>
  );
};

export default memo(SingleComment);
