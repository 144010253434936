import { Link, useLocation } from "react-router-dom";
import { v4 as uid } from "uuid";
const MENU_LIST = [
  {
    id: uid(),
    label: "Home",
    url: "/",
  },
  {
    id: uid(),
    label: "Node",
    url: "/nodes",
  },
  {
    id: uid(),
    label: "Tree Page",
    url: "/tree",
  },
];
const Menu = () => {
  const { pathname } = useLocation();

  return (
    <div
      className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
      id="mobile-menu-2"
    >
      <ul className="mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium">
        {MENU_LIST.map((link) => (
          <li key={link.id}>
            <Link
              to={link.url}
              className={`block rounded py-2 pr-4 pl-3   dark:text-white md:bg-transparent  md:p-0 ${
                pathname === link.url ? "text-blue-700" : "text-gray-700"
              } `}
              aria-current="page"
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
