import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAddReplyMutation } from "../../features/comment/commentApiSlice";
import SimpleLoader from "../Shared/Loader/SimpleLoader";
const AddReply = ({ commentId, setIsShowReplies }) => {
  const { userPicture, firstName, lastName } = useSelector(
    (state) => state.auth.user
  );

  const [addReply, { isLoading, isSuccess, isError, error }] =
    useAddReplyMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = ({ text }) => {
    addReply({ text, commentId });
  };

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);
  useEffect(() => {
    if (isError) {
      console.log(error.data);
    }
  }, [isError, error]);

  return (
    <>
      <hr />
      <form
        className="flex items-start space-x-4 py-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <img
          className="h-10 w-10 rounded-full object-cover"
          src={
            userPicture
              ? userPicture
              : `https://ui-avatars.com/api/?name=${firstName} ${lastName}`
          }
          alt={"title"}
        />

        <div className=" space-y-1 font-medium text-gray-900 dark:text-white">
          <div>
            <input
              type="text"
              className="rounded-lg border py-2 px-2 text-gray-500 outline-none"
              placeholder="reply here"
              {...register("text", { required: "This field is required" })}
            />
            {errors?.text && (
              <p className="mt-1 text-sm text-red-500">
                {errors?.text?.message}
              </p>
            )}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="rounded-md border bg-primary px-4 py-1.5 text-white disabled:bg-gray-700"
            disabled={isLoading}
          >
            {isLoading ? <SimpleLoader /> : "Add"}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddReply;
