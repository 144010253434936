import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../../features/auth/authSelector";
import AddFlayer from "./AddFlayer";
import DropDownList from "./DropDownList";

const FlayerDropDown = ({ isShow, allWords, id }) => {
  const user = useSelector(userSelector);
  
  const myWords = useMemo(() => {
    return allWords.filter((i) => i.user === user._id);
  }, [user, allWords]);

  return (
    <div
      className={`${isShow ? "w-full" : "w-0"} transition-all duration-1000`}
      style={{ zIndex: 9999999 }}
    >
    
      <AddFlayer id={id} />
      <div>
        <div
          id="dropdown"
          className={`flayer-dropdown z-10 block h-64 w-full divide-y divide-gray-100 overflow-x-auto rounded bg-white shadow dark:bg-gray-700`}
          style={{
            position: "absolute",
            inset: "0px auto auto 0px",
            margin: 0,
            top: "60px",
            zIndex: 99999,
          }}
        >
          <div>
            <p className="px-4 pt-2 text-lg font-bold"> My Words</p>
            <hr />
            <DropDownList items={myWords} />
          </div>
          <div>
            <p className="px-4 pt-2 text-lg font-bold"> All</p>
            <hr />
            <DropDownList items={allWords} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlayerDropDown;
