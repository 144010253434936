import { memo, useMemo } from 'react';
import { useGetRepliesQuery } from '../../features/comment/commentApiSlice';
import Reply from './Reply';
const Replies = ({ commentId }) => {
  const { data, isLoading, isError } = useGetRepliesQuery();

  const replies = useMemo(() => {
    if (isLoading || isError) return [];
    return data.filter((reply) => reply.commentId === commentId);
  }, [isLoading, isError, commentId, data]);
  return (
    <div>
      <div className='py-3'>
        {replies.map((reply, i) => (
          <Reply
            key={Math.random()}
            text={reply.text}
            userName={`${reply?.user?.firstName} ${reply?.user?.lastName}`}
            image={reply?.user?.userPicture}
            createdAt={reply.createdAt}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(Replies);
